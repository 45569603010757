import { ReactElement } from 'react';

import { Redirect, Route } from 'react-router';

import { UnreachableError } from 'shared/src/types/unreachable-error';

import { InsightsRedirect } from 'components/insights-redirect/insights-redirect';
import { MatsRedirect } from 'components/mats-redirect/mats-redirect';
import { PeepsRedirect } from 'components/peeps-redirect/peeps-redirect';
import { AboutPage } from 'pages/about/about';
import { BlogAuthorPage } from 'pages/blog-author/blog-author';
import { BlogIndexPage } from 'pages/blog-index/blog-index';
import { BlogPostRoute } from 'pages/blog-post/blog-post';
import { BookATimePageRoute } from 'pages/book-a-time/book-a-time';
import { ConditionArticleRoute } from 'pages/condition-article/condition-article';
import { ConditionCategoryPage } from 'pages/condition-category/condition-category';
import { ConditionLandingPageRoute } from 'pages/condition-landing-page/condition-landing-page';
import { ContentHubPage } from 'pages/content-hub/content-hub';
import { DownloadDeiReport, RequestDemo } from 'pages/dei-report/hubspot-form';
import { GuidesDirectoryRoute } from 'pages/guides-directory/guides-directory';
import { MedicalReviewerRoute } from 'pages/medical-reviewer/medical-reviewer';
import { MedicationArticleRoute } from 'pages/medication-article/medication-article';
import { MedicationsDirectory } from 'pages/medications-directory/medications-directory';
import { NotFound } from 'pages/not-found/not-found';
import { PatientStoryPageRoute } from 'pages/patient-story/patient-story';
import { Patients } from 'pages/patients/patients';
import { PrivacyPolicy } from 'pages/privacy/privacy';
import { PublicTrialRoute } from 'pages/public-trial/public-trial';
import { TermsOfUse } from 'pages/terms-of-use/terms-of-use';
import { TopicHubPageRoute } from 'pages/topic-hub-page/topic-hub-page';
import { TrialsPublicRoute } from 'pages/trials-public/trials-public';
import { WebflowPage, WebflowPageContentOnly } from 'pages/webflow-page/webflow-page';
import { WhyClinicalTrials } from 'pages/why-clinical-trials';

import { DeiReport } from '../../pages/dei-report/dei-report';

import { crawlablePages, webflowPages, WebflowPageVariant } from './page-paths-crawlable';

// hardcoded list of existing paths we should redirect to MATS
const matsRoutes: ReactElement[] = [
  '/signup',
  '/signup/:conditionId(\\d+)',
  '/signup/name',
  '/signup/email',
  '/signup/terms',
  '/signup/existing',
  '/verify-email',
  '/password-reset',
  '/logout',
  '/signup/country',
  '/signup/suburb',
  '/signup/biological-sex',
  '/signup/date-of-birth',
  '/signup/phone',
  '/signup/phone/code-sent/:channel?',
  '/login',
  '/signup/363',
  '/login/existing',
  '/profile/:activeTab?',
  '/profile/edit/:attributeDefinitionId',
  '/profile/add/:attributeType',
  '/trials',
  '/trials/my-matches',
  '/trials/my-applications',
  '/trials/:trialId',
  '/trials/:trialId/locations',
  '/trials/:trialId/application-submitted',
  '/trials/:trialId/application-submitted/:trialSiteId',
  '/trials/:trialId/application-updated',
  '/trials/:trialId/instructions/:trialSiteId',
  '/verify-name',
  '/verify-phone',
  '/verify-phone/code-sent/:channel?',
  '/questionnaire',
  '/questionnaire/condition/:conditionId',
  '/questionnaire/condition/:conditionId/question/:attributeDefinitionId',
  '/questionnaire/condition/:conditionId/success',
  '/questionnaire/condition/:conditionId/no-match',
  '/add-condition',
  '/learn/:conditionId?',
].map(path => <MatsRedirect key={path} path={path} exact />);

// hardcoded list of existing paths we should redirect to PEEPS
const peepsRoutes: ReactElement[] = ['/portal/login'].map(path => (
  <PeepsRedirect key={path} path={path} exact />
));

const insightsRoutes: ReactElement[] = ['/insights/login'].map(path => (
  <InsightsRedirect key={path} path={path} exact />
));

/*
 * List of pages to be rendered on both the server *and* the client, includes
 * basically anything that _doesn't_ use specific patient's data when rendering
 *
 * We use manual keys here as a scrappiest way to avoid React children keys warning
 */
export const pages: ReactElement[] = [
  <Redirect exact key="/covid" from="/covid" to="/" />,
  <Redirect exact key="/covid-signup" from="/covid-signup" to="/" />,
  <Redirect exact key="/:slug/trials" from="/:slug/trials" to="/:slug/clinical-trials" />,

  /* Content pages, accessible to everyone */
  ...Object.values(webflowPages).map(({ variant, title, webflowPath, ...route }) => {
    switch (variant) {
      case WebflowPageVariant.nav:
        return (
          <Route
            {...route}
            render={() => <WebflowPage windowTitle={title} webflowPath={webflowPath} />}
          />
        );
      case WebflowPageVariant.contentOnly:
        return (
          <Route
            {...route}
            render={() => <WebflowPageContentOnly windowTitle={title} webflowPath={webflowPath} />}
          />
        );
      default:
        throw new UnreachableError(variant);
    }
  }),
  <Route {...crawlablePages.AboutPage} component={AboutPage} />,
  <Route {...crawlablePages.PrivacyPolicy} component={PrivacyPolicy} />,
  <Route {...crawlablePages.TermsOfUse} component={TermsOfUse} />,
  <Route {...crawlablePages.WhyClinicalTrials} component={WhyClinicalTrials} />,
  <Route {...crawlablePages.DeiReport} component={DeiReport} />,
  <Route {...crawlablePages.GuidesDirectoryRoute} component={GuidesDirectoryRoute} />,
  <Route {...crawlablePages.MedicationsDirectoryRoute} component={MedicationsDirectory} />,
  <Route {...crawlablePages.PatientsPage} component={Patients} />,
  <Route
    exact
    key="/dei-report/request-demo"
    path="/dei-report/request-demo"
    component={RequestDemo}
  />,
  <Route
    exact
    key="/dei-report/download"
    path="/dei-report/download"
    component={DownloadDeiReport}
  />,
  <Route exact key="/hub" path="/hub" component={ContentHubPage} />,
  <Route
    exact
    key="/book-a-time/:projectId([\dA-Fa-f\-]+)"
    path="/book-a-time/:projectId([\dA-Fa-f\-]+)"
    component={BookATimePageRoute}
  />,
  <Route
    exact
    key="/:category/trials/:id"
    path="/:category/trials/:id"
    component={PublicTrialRoute}
  />,

  <Route exact key="/clinical-trial/:id" path="/clinical-trial/:id" component={PublicTrialRoute} />,

  /* Pages created dynamically from Contentful data */
  <Route
    exact
    key="/patients/story-:slug"
    path="/patients/story-:slug"
    component={PatientStoryPageRoute}
  />,
  <Route
    exact
    key="/patients/:slug"
    path="/patients/:slug"
    component={ConditionLandingPageRoute}
  />,
  <Route exact key="/reviewers/:slug" path="/reviewers/:slug" component={MedicalReviewerRoute} />,
  <Route exact key="/authors/:slug" path="/authors/:slug" component={BlogAuthorPage} />,
  <Route {...crawlablePages.BlogIndexPage} component={BlogIndexPage} />,
  <Route exact key="/blog/:slug" path="/blog/:slug" component={BlogPostRoute} />,
  <Route
    exact
    key="/medications/:slug"
    path="/medications/:slug"
    component={MedicationArticleRoute}
  />,
  <Route exact key="/topic/:slug" path="/topic/:slug" component={TopicHubPageRoute} />,
  // Checks if there's a condition category with that top-level slug, falls back to NotFound otherwise
  <Route exact key="/:slug" path="/:slug" component={ConditionCategoryPage} />,
  <Route
    exact
    key="/:slug/clinical-trials"
    path="/:slug/clinical-trials"
    component={TrialsPublicRoute}
  />,
  <Route exact key="/:category/:slug" path="/:category/:slug" component={ConditionArticleRoute} />,
  // Technically non needed, because `ConditionCategoryPageRoute` above catches
  // everything, so it's here a precaution if that ever changes
  <Route key="/404" component={NotFound} />,
];

export const hmioPages: ReactElement[] = [
  /* Redirect used to track conversions */
  ...matsRoutes,
  ...peepsRoutes,
  ...insightsRoutes,
  <Route exact key="/syd" path="/syd" render={() => <Redirect to="/" />} />,
];
